<template>
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        Framework Compliance over time
      </h3>
    </div>
    <div class="card-body p-0">
      <!--begin::Chart-->
      <apexchart
        ref="realtimeChart"
        type="line"
        height="235"
        :options="chartOptions"
        :series="getSeries"
      ></apexchart>
      <!--end::Chart-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "function-status-by-time",
  props: {
    functionData: Object,
  },    
  data() {

    var getMonths = this.getMonths;

    console.log('dsadasdads',getMonths)

    return {
      series: [
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false
          },          
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 1
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        yaxis: {
          max: 100,
          step:20,
          labels: {   
            formatter: (value) => {if (value <= 100) { return value + '%'} else {return ''} },
          },        
        },
        xaxis: {
          categories: getMonths,
          title: {
            text: 'Month'
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    getSeries() {

      var retArr = [];
       
      this.functionData.forEach(functionData => {

        console.log('functionData',functionData)

        var singleItem = [];
        functionData.monthly_stats.forEach(stat => {
            singleItem.push(stat.data.current.value)
        });

        singleItem.push(functionData.monthly_stats[functionData.monthly_stats.length-1].data.goal.value)

        retArr.push({
          name: functionData.category_code + '-' + functionData.function,
          data: singleItem
        })

      });


      return retArr;

    },   
    getMonths() {

      var retArr = [];
      
      var functionData = this.functionData[0]

      console.log('functionData',functionData)
      if (functionData) {
        
        functionData.monthly_stats.forEach(stat => {
            retArr.push(stat.month + ' ' + stat.year)
        });

        retArr.push('Target')


      }

      return retArr;

    }    
  },
  mounted() {
    console.log('this.getMonths',this.getMonths)
    this.chartOptions.xaxis.categories = this.getMonths;
    this.$refs.realtimeChart.updateOptions({ labels:  this.getMonths, });
  },
};
</script>
