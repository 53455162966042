<template>
  <div>
    
    
  <div style="width:100%;height: 100%;display: flex;align-items: center;justify-content: center;padding-top:30vh" v-if="!showData">
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-lg'" style="font-size:30px"></div>
  </div>    
    
    
    

    <div v-if="showData">

      <div class="row">
        <!--
        <div class="col-xxl-4">
          <ComplianceStatus></ComplianceStatus>
        </div>
        <div class="col-xxl-4">
          <FunctionStatus></FunctionStatus>
        </div>
        <div class="col-xxl-4">
          <CategoryStatus></CategoryStatus>
        </div>
              -->
        <div class="col-xxl-12">
          <FunctionStatusByTime :functionData="frameworkStats"></FunctionStatusByTime>
        </div>
        <div class="col-xxl-3"      v-if="currentFrameWorkData.show_risks == 1">
          <RiskStatus :threatdata="threatdata.threat_series_data"></RiskStatus>
        </div>
        <div class="col-xxl-3"      v-if="currentFrameWorkData.show_risks == 1">
          <RiskList :threatdata="threatdata.top_threats"></RiskList>
        </div>        
        <div class="col-xxl-3"      v-if="currentFrameWorkData.show_risks == 1">
          <RiskSystemList :systems="threatdata.top_systems"></RiskSystemList>
        </div>        
        <div class="col-xxl-3"      v-if="currentFrameWorkData.show_risks == 1">
          <RiskControlsList :systems="threatdata.top_controls"></RiskControlsList>
        </div>                
        <!--
        <div class="col-xxl-4">
          <ComplianceStatusByTime></ComplianceStatusByTime>
        </div>
        -->

      </div>
      <div class="row" v-if="showData">
          <div class="col-xxl-3" v-for="(frameworkStat, i) in frameworkStats" :key="i">
            <FunctionSummaryStatus :functionData="frameworkStat" :currentFrameworkStatsPerc="currentFrameworkStatsPerc"></FunctionSummaryStatus>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_DASHBOARD_STATS,GET_DASHBOARD_STATS_PERC } from "@/core/services/store/framework.module";



//import ComplianceStatusByTime from "@/view/content/dashboard/ComplianceStatusByTime.vue";
import FunctionSummaryStatus from "@/view/content/dashboard/FunctionSummaryStatus.vue";
//import ComplianceStatus from "@/view/content/dashboard/ComplianceStatus.vue";
//import FunctionStatus from "@/view/content/dashboard/FunctionStatus.vue";
import FunctionStatusByTime from "@/view/content/dashboard/FunctionStatusByTime.vue";
import RiskStatus from "@/view/content/dashboard/RiskStatus.vue";
import RiskList from "@/view/content/dashboard/RiskList.vue";
import RiskSystemList from "@/view/content/dashboard/RiskSystemList.vue";
import RiskControlsList from "@/view/content/dashboard/RiskControlsList.vue";

//import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";

export default {
  name: "dashboard",
  components: {
    FunctionSummaryStatus,
    //ComplianceStatus,
    //ComplianceStatusByTime,
    //FunctionStatus,
    FunctionStatusByTime,
    RiskStatus,
    RiskList,
    RiskSystemList,
    RiskControlsList,
    //    StatsWidget12
  },
  data() {
    return {
      frameworkStats: [],
      threatdata: [],
      showData: true,

    };
  },  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.$nextTick(() => {    


      this.showData = false;
      this.frameworkStats = this.getFrameworkStatsData(this.currentFrameworkStats.frameworkStats);
      this.threatdata = this.currentFrameworkStats.threats;


      if (this.frameworkStats === undefined || this.frameworkStats.length == 0) {
        this.showData = false;
      } else {
        this.showData = true;        
      }



      this.getData(); 
      });

  },
  methods: {
    getData() {
      var frameworkID = '';
      if (this.currentFramework.framework) {
        frameworkID = this.currentFramework.framework.id;
      }

      console.log('this.currentFrameworkStats',this.currentFrameworkStats)
      this.$store.dispatch(GET_DASHBOARD_STATS,frameworkID);
      this.$store.dispatch(GET_DASHBOARD_STATS_PERC,frameworkID);      
      
    },    
    getFrameworkStatsData(frameworkStats) {
      var results = [];
      if (frameworkStats) {
        frameworkStats.forEach((framework_item) => {

            framework_item.category_no = parseInt(framework_item.category_code.replace( /^\D+/g, ''));


            results.push(framework_item);

        });
      }

      results = results.sort((a, b) => (a.category_no > b.category_no) ? 1 : -1);
      return results;
    },

  },
  computed: {
    ...mapGetters(["selectedFramework","currentFramework","currentFrameworks","currentFrameworkStats","layoutConfig","currentFrameworkStatsPerc"]),
    currentFrameWorkData() {

      
      var id = this.selectedFramework;
      var currentFramework = this.currentFrameworks.filter(
        function(o) {
          console.log(o,id);
          return o.id == id;
        }
      );

      if (currentFramework.length > 0) {
        return currentFramework[0];
      } else {
        return this.currentFrameworks[0];
      }

      
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },           
  },
  watch: {
    currentFrameworkStats: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      this.showData = false;
      this.frameworkStats = this.getFrameworkStatsData(this.currentFrameworkStats.frameworkStats);
      this.threatdata = this.currentFrameworkStats.threats;
      this.showData = true;
    }, 
  },    
};
</script>
<style>
.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.loader {
  left: 50%;
  margin-left: -4em;
}

</style>