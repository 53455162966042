<template>
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Risk Status Breakdown</h3>

    </div>
    <div class="card-body p-0">
      <!--begin::Chart-->
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
        height="380px"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-7",
  props: {
    threatdata: Object,
  },      
  data() {
    return {
        series: [{
          name: 'Low',
          data: [0],

        }, {
          name: 'Medium',
          data: [0]
        }, {
          name: 'High',
          data: [0]
        }, {
          name: 'Very High',
          data: [0]
        }],
        chartOptions: {
          colors : ['#44C14A', '#FFFF00', '#FFA500', '#BB413E'],
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
          toolbar: {
            show: false
          },              
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          xaxis: {
            categories: ['Risk'
            ],
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 50,
            onItemClick: {
                toggleDataSeries: false
            },            
          },
        },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },    
  mounted() {



  },

  watch: {
    threatdata: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);

      console.log('Risk status',this.threatdata)
      if (this.threatdata) {

        var series = [];
        this.threatdata.series.forEach(threat => {
          series.push({
            name: threat.name,
            data: [threat.data]
          })
        });

        this.series = series;


      }      
    },
  },  
};
</script>
