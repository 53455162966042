export const getRiskColor = (impact,probability,riskreview) => {

    var score = (impact * probability)

    if (score <= riskreview.low_score) {
        return 'background-color: ' + riskreview.low_color + ' !important';
      } else if (score <= riskreview.med_score) {
        return 'background-color: ' + riskreview.med_color + ' !important';
      } else if (score <= riskreview.high_score) {
        return 'background-color: ' + riskreview.high_color + ' !important';
      } else {
        return 'background-color: ' + riskreview.vhigh_color + ' !important';
      }

};
  
export default { getRiskColor };
  