<template>
  <div class="card card-custom gutter-b" @click="gotToDetail(functionData)" style="cursor:pointer">
    <!--begin::Body-->
    <div class="card-header border-1" style="width:100%">
      <h3 style="padding-top:2vh; width:100%;display:block; text-align:center;vertical-align: center;font-size:16px">
        {{functionData.category_code}}-{{functionData.function}}
      </h3>
    </div>
    <div class="card-body p-0">    
      <div style="padding:10px">
        <div class="functionSummaryStatBigPerc">{{getCurrentPercetage()}}%</div>
        <div class="functionSummaryStatBigPercRight"><small-goal-status title="" :data="getItemsGrpahFrameworkValue('current',functionData.category_code)" /></div>
        <div style="padding-top:40px;">
          <apexchart
            type="line"
            height="150"
            :options="chartOptions"
            :series="getSeries"
          ></apexchart>          
        </div>
        <div :class="[getPreviousPercetage()<0?'functionSummaryStatSmallPercDown':'functionSummaryStatSmallPerc']">{{getPreviousPercetage()}}%</div>
        <div style="font-size:12px;text-align:center">vs Previous month</div>     
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SmallGoalStatus from "@/view/content/dashboard/SmallGoalStatus.vue";

export default {
  name: "function-summary-status",
  props: {
    functionData: Object,
    currentFrameworkStatsPerc: Object,
  },  
  components: {
    SmallGoalStatus
  },  
  data() {
    return {
          series: [{
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 50]
          }],
          chartOptions: {
            chart: {
              height: 100,
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: '',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              labels: {
                show: false,
              },
              axisTicks: {
                show: false,
              }

            },
            yaxis: {
              labels: {
                show: false,
              },
              axisTicks: {
                show: false,
              }

            }
          },      
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    getSeries() {

      var retArr = [{
              data: []
          }];
      if (this.functionData.monthly_stats) {
        this.functionData.monthly_stats.forEach(stat => {
            retArr[0].data.push(stat.data.current.value)
        });
      } else {
        retArr[0].data = [0,0,0,0,0,0,0,0,0,0,0,0];
      }
      return retArr;

    } 
  },
  mounted() {},
  methods: {
    getCurrentPercetage() {
      var retStr = 'N/A';
      if (this.functionData.monthly_stats) {
          var lastOne = this.functionData.monthly_stats[this.functionData.monthly_stats.length-1];
          retStr = lastOne.data.current.value;
      }
      return retStr;
    },
    gotToDetail(functionData) {
      console.log(functionData)
      this.$router.push({ path: "/workplans?show=" + functionData.category_code })
    },
    getPreviousPercetage() {

      var retStr = 'N/A';
      if (this.functionData.monthly_stats) {
          var lastOne = this.functionData.monthly_stats[this.functionData.monthly_stats.length-1];
          var lastOnePrevious = this.functionData.monthly_stats[this.functionData.monthly_stats.length-2];
          retStr = (lastOne.data.current.value - lastOnePrevious.data.current.value);
      }
      return retStr;

    },
    getItemsGrpahFrameworkValue(type,code){


      var statValues = this.currentFrameworkStatsPerc.topLevelCount.filter(function(o) {return o.category_code == code;});

      if (statValues.length > 0) {
        if (type=='goal') {
          return statValues[0].goal;
        }
        if (type=='current') {
          return statValues[0].status;
        }

      }      


      return 0;

    },    
   
  }  
};
</script>

<style>
.functionSummaryStatBigPerc {
  width:50%;
  font-size:38px;
  text-align:left;
  padding-left:20px;
  float:left;
}
.functionSummaryStatBigPercRight {
  width:50%;
  font-size:38px;
  text-align:right;
  padding-right:20px;
  float:right;
}
.functionSummaryStatSmallPerc {
  font-size:18px;
  text-align:center;
  color:green;
}
.functionSummaryStatSmallPercDown {
  padding-top:30px;
  font-size:18px;
  text-align:center;
  color:coral
}
</style>
